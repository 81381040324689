import { configureStore } from "@reduxjs/toolkit"
import { addressApi } from "./API/AddressApi"
import { authApi } from "./API/AuthApi"
import { pokemonApi } from "./API/DummyApi"
import { globalApi } from "./API/globalApi"
import { homeApi } from "./API/HomeApi"
import { productApi } from "./API/ProductApi"
import { shippingApi } from "./API/ShippingApi"
import { trasactionApi } from "./API/TransactionApi"
import { uploadApi } from "./API/UploadApi"
import AddressReducer from "./Reducer/AddressReducer"
import AuthReducer from "./Reducer/AuthReducer"
import DummyReducer from "./Reducer/DummyReducer"
import GlobalReducer from "./Reducer/GlobalReducer"
import HomeReducer from "./Reducer/HomeReducer"
import ProductReducer from "./Reducer/ProductReducer"
import TransactionReducer from "./Reducer/TransactionReducer"
import LogRocket from "logrocket"
import { referralApi } from "./API/ReferralApi"

//saga
// let sagaMiddleware = createSagaMiddleware();
// const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

export const Store = configureStore({
  reducer: {
    dummy: DummyReducer,
    auths: AuthReducer,
    product: ProductReducer,
    home: HomeReducer,
    transaction: TransactionReducer,
    address: AddressReducer,
    global: GlobalReducer,
    [pokemonApi.reducerPath]: pokemonApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [trasactionApi.reducerPath]: trasactionApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [shippingApi.reducerPath]: shippingApi.reducer,
    [globalApi.reducerPath]: globalApi.reducer,
    [referralApi.reducerPath]: referralApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      pokemonApi.middleware,
      authApi.middleware,
      homeApi.middleware,
      productApi.middleware,
      trasactionApi.middleware,
      uploadApi.middleware,
      globalApi.middleware,
      shippingApi.middleware,
      referralApi.middleware,
      LogRocket.reduxMiddleware()
    ),
  devTools:
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ serialize: true }),
})
