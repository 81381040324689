import React from "react"
import { Provider as AlertProvider, positions, transitions } from "react-alert"
import AlertTemplate from "react-alert-template-basic"
import ReactDOM from "react-dom"
import TagManager from "react-gtm-module"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "tw-elements"
import App from "./App"
import { Store } from "./App/Store"
import { onMessageListener } from "./firebase"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

export const tagManagerArgs = {
  gtmId: "GTM-KQVQBW4",
}

// optional configuration
const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.FADE,
}

TagManager.initialize(tagManagerArgs)

if (process.env.NODE_ENV !== "development") {
  console.error = () => {}
  console.debug = () => {}
  console.log = () => {}
}

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer />
    <AlertProvider
      template={AlertTemplate}
      containerStyle={{
        textTransform: "capitalize",
        fontFamily: "DM Sans",
        fontWeight: "lighter",
        fontSize: 12,
        zIndex: 1000,
      }}
      {...options}
    >
      <Provider store={Store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
