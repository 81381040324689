import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrl } from "../../utils/constant"

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Authorization", localStorage.getItem("auth_token"))
      return headers
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (auth) => ({
        url: `/auth/login`,
        method: "POST",
        body: auth,
      }),
    }),
    register: builder.mutation({
      query: (auth) => ({
        url: `/auth/register`,
        method: "POST",
        body: auth,
      }),
    }),
    logout: builder.mutation({
      query: (token) => ({
        url: `/auth/logout-user`,
        method: "POST",
        body: { token },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (auth) => ({
        url: `/auth/forgot-password`,
        method: "POST",
        body: auth,
      }),
    }),
    resetPassword: builder.mutation({
      query: (auth) => ({
        url: `/auth/reset-password`,
        method: "POST",
        body: auth,
      }),
    }),

    //update profile
    updateProfile: builder.mutation({
      query: (auth) => ({
        url: `/user/update`,
        method: "POST",
        body: auth,
      }),
    }),

    // check auth
    checkAuth: builder.mutation({
      query: (token) => ({
        url: `/auth/check-auth`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
    }),

    updatePassword: builder.mutation({
      query: (auth) => ({
        url: `/user/update-password`,
        method: "POST",
        body: auth,
      }),
    }),

    // check user mutation
    checkUser: builder.mutation({
      query: (auth) => ({
        url: `/auth/check-user`,
        method: "POST",
        body: auth,
      }),
    }),

    // user validate
    userValidate: builder.mutation({
      query: (auth) => ({
        url: `/user/user-validate`,
        method: "POST",
        body: auth,
      }),
    }),

    // login by email
    loginByEmail: builder.mutation({
      query: (auth) => ({
        url: `/auth/login-by-email`,
        method: "POST",
        body: auth,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdateProfileMutation,
  useLogoutMutation,
  useCheckAuthMutation,
  useUpdatePasswordMutation,
  useCheckUserMutation,
  useUserValidateMutation,
  useLoginByEmailMutation,
} = authApi
