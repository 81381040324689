import LogRocket from "logrocket"
import "moment/locale/id"
import React, { useEffect, useState } from "react"
import ReactPixel from "react-facebook-pixel"
import ReactGA from "react-ga"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../src/Layout/Layout"
import "./App.less"
import { useCheckAuthMutation } from "./App/API/AuthApi"
import { useGetUserProfileMutation } from "./App/API/HomeApi"
import {
  setResponseMessage,
  setStatus,
  setToken,
  setUser,
} from "./App/Reducer/AuthReducer"
import "./firebase"
import { GetToken, onMessageListener } from "./firebase"
import { fbpixelArr } from "./utils/constant"
import { toast } from "react-toastify"

// import { GetToken } from "./firebase"

LogRocket.init("5msc6v/aimi-project")

function App() {
  const dispatch = useDispatch()
  const { status, token, user } = useSelector((state) => state.auths)
  const [checkAuth] = useCheckAuthMutation()
  const [getUserProfile] = useGetUserProfileMutation()
  // console.log(user, "user")

  // This is an example script - don't forget to change it!

  useEffect(() => {
    LogRocket.identify(user?.id, {
      name: user?.name,
      email: user?.email,
      // Add your own custom user variables here, ie:
      // subscriptionType: 'pro'
    })

    // return () => {}
  }, [])

  // facebook pixel
  // const options = {
  //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //   debug: false, // enable logs
  // }

  // fbpixelArr?.forEach((pxl) => {
  //   ReactPixel.init(pxl, {}, options)
  // })

  // useEffect(() => {
  //   ReactPixel.pageView()
  //   ReactPixel.track("ViewContent")
  // }, [])

  // // google analytic
  // ReactGA.initialize(process.env["REACT_APP_GTM"], {
  //   debug: false,
  //   titleCase: false,
  // })

  const handleGetUserProfile = () => {
    getUserProfile().then(({ error, data }) => {
      if (!error) {
        dispatch(setUser(data?.data))
      }
    })
  }
  const handleCheckAuth = () => {
    checkAuth(token).then(({ error, data }) => {
      if (error) {
        localStorage.removeItem("auth_token")
        localStorage.removeItem("user_data")
        localStorage.removeItem("selectedAddress")
        localStorage.removeItem("defaultForm")
        localStorage.removeItem("user")
        localStorage.removeItem("ip_address")
        localStorage.removeItem("email")
        localStorage.removeItem("selected_address")
        localStorage.removeItem("guest_user")
        localStorage.removeItem("transaction_data")
        localStorage.removeItem("_address")
        localStorage.removeItem("transactionSuccess")
        localStorage.removeItem("transaction_detail")
        dispatch(setToken(null))
        dispatch(setUser(null))
      }
    })
  }

  useEffect(() => {
    if (token) {
      handleGetUserProfile()
      handleCheckAuth()
    }
  }, [])

  // push notification
  const [isTokenFound, setTokenFound] = useState(false)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    // Ensure that the browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support system notifications")
      return
    }

    // Request permission from the user to show notifications
    Notification.requestPermission().then(async (permission) => {
      console.log(permission, "permission")
      if (permission === "granted") {
        GetToken(setTokenFound)
      }
    })
  }, [message])
  // console.log(isTokenFound, "isTokenFound")

  onMessageListener()
    .then((payload) => {
      setMessage(payload?.messageId)
      console.log("onMessage: ", payload)
      toast.info(payload?.notification?.title)
      // Create a notification
      const notification = new Notification("okeeeeeee", {
        body: "okeeeeee",
      })
      console.log(notification, "notification")
      // Define behavior for when the notification is clicked
      notification.onclick = () => {
        console.log("Notification clicked")
        // You can add custom behavior here when the notification is clicked
      }
    })
    .catch((err) => console.log("failed: ", err))

  useEffect(() => {
    if (status !== "loading") {
      setTimeout(() => {
        dispatch(setStatus("loading"))
        dispatch(setResponseMessage(null))
      }, 3000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <div className="bg-[#E5E5E5]">
      <Layout />
    </div>
  )
}

export default App
