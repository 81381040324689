import React from "react"
import Router from "./Router"

function Layout() {
  document.title = "Flimty"
  return (
    <div className="shadow-horizontal max-w-lg min-h-screen mx-auto my-0 bg-white">
      <Router />
    </div>
  )
}
export default Layout
