import { createSlice } from "@reduxjs/toolkit"
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
} from "../../utils/helper"

const initialState = {
  status: "completed",
  message: null,

  paymentMethodLists: [],
  selectedPayment: getDataFromLocalStorage("selected_payment"),
  cart: getDataFromLocalStorage("cart"),
  transactionData: getDataFromLocalStorage("transaction_data"),
  shippingMethod: getDataFromLocalStorage("shipping_method"),
  addressList: [],
  selectedAddress: getDataFromLocalStorage("selected_address"),
  selectedVoucher: getDataFromLocalStorage("voucherApplied"),
  transactionSuccess: getDataFromLocalStorage("transactionSuccess"),
  transactionActive: [],
  transactionHistory: [],
  transactionDetail: null,
  vouchers: [],
  voucherDetail: null,
  voucherPoint: [],
  voucherPointHistory: [],
  selectedShipping: null,
  selectedShippingMethod: getDataFromLocalStorage("selected_shipping_method"),
  tabInformationWarehouse:
    getDataFromLocalStorage("tabInformationWarehouse") || 1,
  tabInformationWarehouseAddress: getDataFromLocalStorage(
    "tabInformationWarehouseAddress"
  ),
  shippingLists: [],
  shippingLoading: false,
  warehouses: [],
  selectedWarehouse: getDataFromLocalStorage("selected_warehouse"),
  totalTransaction: 0,
}

// 2. create reducer
export const TransactionReducer = createSlice({
  name: "home",
  initialState,
  reducers: {
    setPaymentMethodLists: (state, { payload }) => {
      return {
        ...state,
        paymentMethodLists: payload,
      }
    },
    setSelectedPayment: (state, { payload }) => {
      localStorage.setItem("selected_payment", JSON.stringify(payload))
      return {
        ...state,
        selectedPayment: payload,
      }
    },
    setCart: (state, { payload }) => {
      localStorage.setItem("cart", JSON.stringify(payload))
      return {
        ...state,
        cart: payload,
      }
    },
    setTransactionData: (state, { payload }) => {
      localStorage.setItem("transaction_data", JSON.stringify(payload))
      return {
        ...state,
        transactionData: payload,
      }
    },
    setShippingMethod: (state, { payload }) => {
      if (payload) {
        localStorage.setItem("shipping_method", JSON.stringify(payload))
      } else {
        removeDataFromLocalStorage("shipping_method")
      }
      return {
        ...state,
        shippingMethod: payload,
      }
    },
    setAddressList: (state, { payload }) => {
      return {
        ...state,
        addressList: payload,
      }
    },
    setSelectedAddress: (state, { payload }) => {
      return {
        ...state,
        selectedAddress: payload,
      }
    },
    setTransactionResponse: (state, { payload }) => {
      return {
        ...state,
        transactionSuccess: payload,
      }
    },
    setTransactionActive: (state, { payload }) => {
      return {
        ...state,
        transactionActive: payload,
      }
    },
    setTransactionHistory: (state, { payload }) => {
      return {
        ...state,
        transactionHistory: payload,
      }
    },
    setTransactionDetail: (state, { payload }) => {
      localStorage.setItem("transaction_detail", JSON.stringify(payload))
      return {
        ...state,
        transactionDetail: payload,
      }
    },
    setVouchers: (state, { payload }) => {
      return {
        ...state,
        vouchers: payload,
      }
    },
    setVoucherDetail: (state, { payload }) => {
      return {
        ...state,
        voucherDetail: payload,
      }
    },
    setSelectedVoucher: (state, { payload }) => {
      return {
        ...state,
        selectedVoucher: payload,
      }
    },
    setVoucherPoint: (state, { payload }) => {
      return {
        ...state,
        voucherPoint: payload,
      }
    },

    setVoucherPointHistory: (state, { payload }) => {
      return {
        ...state,
        voucherPointHistory: payload,
      }
    },

    setSelectedShipping: (state, { payload }) => {
      return {
        ...state,
        selectedShipping: payload,
      }
    },
    setSelectedShippingMethod: (state, { payload }) => {
      localStorage.setItem("selected_shipping_method", JSON.stringify(payload))
      return {
        ...state,
        selectedShippingMethod: payload,
      }
    },
    setTabInformationWarehouse: (state, { payload }) => {
      localStorage.setItem("tabInformationWarehouse", JSON.stringify(payload))
      return {
        ...state,
        tabInformationWarehouse: payload,
      }
    },
    setTabInformationWarehouseAddress: (state, { payload }) => {
      localStorage.setItem(
        "tabInformationWarehouseAddress",
        JSON.stringify(payload)
      )
      return {
        ...state,
        tabInformationWarehouseAddress: payload,
      }
    },
    setShippingLists: (state, { payload }) => {
      return {
        ...state,
        shippingLists: payload,
      }
    },
    setShippingLoading: (state, { payload }) => {
      return {
        ...state,
        shippingLoading: payload,
      }
    },
    setWarehouses: (state, { payload }) => {
      return {
        ...state,
        warehouses: payload,
      }
    },
    setSelectedWarehouse: (state, { payload }) => {
      localStorage.setItem("selected_warehouse", JSON.stringify(payload))
      return {
        ...state,
        selectedWarehouse: payload,
      }
    },
    setTotalTransaction: (state, { payload }) => {
      return {
        ...state,
        totalTransaction: payload,
      }
    },
  },
})

// 1. create list action for each case
export const {
  setPaymentMethodLists,
  setSelectedPayment,
  setCart,
  setTransactionData,
  setShippingMethod,
  setAddressList,
  setSelectedAddress,
  setTransactionResponse,
  setTransactionActive,
  setTransactionHistory,
  setTransactionDetail,
  setVouchers,
  setVoucherDetail,
  setSelectedVoucher,
  setVoucherPoint,
  setVoucherPointHistory,
  setSelectedShipping,
  setSelectedShippingMethod,
  setTabInformationWarehouse,
  setTabInformationWarehouseAddress,
  setShippingLists,
  setShippingLoading,
  setWarehouses,
  setSelectedWarehouse,
  setTotalTransaction,
} = TransactionReducer.actions

// last export reducer
export default TransactionReducer.reducer
