import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrl } from "../../utils/constant"

export const shippingApi = createApi({
  reducerPath: "shipping_api",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Authorization", "Bearer 4a6a6c7f2cdd12a826e2f15675a6c6ac")
      return headers
    },
  }),
  endpoints: (builder) => ({
    // get Shipping mutation
    getShipping: builder.mutation({
      query: (body) => ({
        url: "/shipping/info",
        method: "POST",
        body,
      }),
    }),
  }),
})

export const { useGetShippingMutation } = shippingApi
