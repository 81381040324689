import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrl } from "../../utils/constant"

export const productApi = createApi({
  reducerPath: "product_api",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Authorization", localStorage.getItem("auth_token"))
      return headers
    },
  }),
  endpoints: (builder) => ({
    tagTypes: [
      "getCategory",
      "getProducts",
      "showWhislist",
      "getProductDetail",
      "getProductFilterList",
      "getCart",
      "getRating",
    ],
    getCategory: builder.query({
      query: () => ({ url: "/category/all" }),
      providesTags: ["getCategory"],
    }),
    getProducts: builder.query({
      query: (auth = null) => ({
        url: auth
          ? "/product/all/customer-portal"
          : "/product/public/all/customer-portal",
      }),
      providesTags: ["getProducts"],
    }),
    addWhislish: builder.mutation({
      query: (body) => ({
        url: `/whislist/add-whislist`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["showWhislist", "getProducts", "getProductDetail"],
    }),
    showWhislist: builder.query({
      query: () => `/whislist/all`,
      providesTags: ["showWhislist"],
    }),
    removeWhislish: builder.mutation({
      query: (body) => ({
        url: `/whislist/remove-whislist`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["showWhislist", "getProducts", "getProductDetail"],
    }),
    // search product with mutation
    searchProduct: builder.mutation({
      query: (body) => ({
        url: `/product/public/search`,
        method: "POST",
        body,
      }),
    }),

    // get product detail
    getProductDetail: builder.query({
      query: ({ auth = null, id }) => ({
        url: auth ? "/product/detail/" + id : "/product/public/detail/" + id,
      }),
      providesTags: ["getProductDetail"],
    }),

    // get product filter
    getProductFilterList: builder.query({
      query: (auth = null) => ({
        url: auth ? "/product/filter-lists/" : "/product/public/filter-lists/",
      }),
      providesTags: ["getProductFilterList"],
    }),

    // apply filter with mutation
    applyFilter: builder.mutation({
      query: (body) => ({
        url: `/product/public/apply-filter`,
        method: "POST",
        body,
      }),
    }),

    // add to cart
    addToCart: builder.mutation({
      query: (body) => ({
        url: body?.token ? `/cart/add-cart` : `/cart/add-guest-cart`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getCart", "getProductDetail"],
    }),

    updateCart: builder.mutation({
      query: (body) => ({
        url: body?.token ? `/cart/update-cart` : `/cart/update-guest-cart`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getCart", "getProductDetail"],
    }),

    // delete cart
    deleteCart: builder.mutation({
      query: (body) => ({
        url: body?.token ? `/cart/delete-cart` : `/cart/delete-guest-cart`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getCart", "getProductDetail"],
    }),

    // get cart
    getCart: builder.query({
      query: (data) => ({
        url: data?.token
          ? `/cart/list-cart`
          : `/cart/list-guest-cart/${data?.ip_address}`,
      }),
      providesTags: ["getCart"],
    }),

    // get rating
    getRating: builder.query({
      query: (product_id) => ({
        url: `comment-rating/${product_id}`,
      }),
      providesTags: ["getRating"],
    }),

    // clear cart
    clearCart: builder.mutation({
      query: (body) => ({
        url: `/cart/clear-cart`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getCart", "getProductDetail"],
    }),

    // select all cart
    selectAllCart: builder.mutation({
      query: (body) => ({
        url: `/cart/select-all-cart`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getCart", "getProductDetail"],
    }),

    // select cart
    selectCart: builder.mutation({
      query: (body) => ({
        url: `/cart/select-cart`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getCart", "getProductDetail"],
    }),

    // select variant
    selectVariant: builder.mutation({
      query: (body) => ({
        url: `/cart/select-variant`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getCart", "getProductDetail"],
    }),
  }),
})

export const {
  useGetCategoryQuery,
  useGetProductsQuery,
  useAddWhislishMutation,
  useRemoveWhislishMutation,
  useShowWhislistQuery,
  useSearchProductMutation,
  useGetProductDetailQuery,
  useGetProductFilterListQuery,
  useApplyFilterMutation,
  useAddToCartMutation,
  useUpdateCartMutation,
  useGetCartQuery,
  useDeleteCartMutation,
  useGetRatingQuery,
  useClearCartMutation,
  useSelectAllCartMutation,
  useSelectCartMutation,
  useSelectVariantMutation,
} = productApi
