const originUrl = window.location.origin.toString()

let isUrlProduction = "crm"

if (originUrl.includes("dev") || originUrl.includes("localhost")) {
  isUrlProduction = "dev"
}

export const baseUrl = `https://${isUrlProduction}api.daftar-agen.com/api/`
export const baseUrlUpload = `https://${isUrlProduction}.daftar-agen.com/api/`
export const invoiceUrl = `https://${isUrlProduction}.daftar-agen.com/invoice/`
// export const baseUrl = "http://fis-api.test/api/"
// export const baseUrlUpload = "http://fis-backend.test/api/"
// export const invoiceUrl = "http://fis-backend./invoice/"
export const isProduction = true

export const fbpixelArr = process.env["REACT_APP_FB_PIXEL"]?.split(",")
// export const shippingUrlSanbox = "https://apiv2.jne.co.id:10102/tracing/api"
// export const shippingUrlProduction =
//   "https://cors-anywhere.herokuapp.com/https://apiv2.jne.co.id:10101/tracing/api"

// export const usernameDev = "APITEST"
// export const apikeyDev = "28c894a9fleala200867ecd9ef674536"

// export const usernameProd = "AKUSARA"
// export const apikeyProd = "10f7788930803b1b7c7a281050737de4"
