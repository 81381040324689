import { createSlice } from "@reduxjs/toolkit";
import { getDataFromLocalStorage } from "../../utils/helper";

const initialState = {
  ip_address: getDataFromLocalStorage("ipAddress"),
  bottomTabVisible: true,
};
// 2. create reducer
export const GlobalReducer = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIpAddress: (state, { payload }) => {
      localStorage.setItem("ipAddress", payload);
      return {
        ...state,
        ip_address: payload,
      };
    },
    setBottomTabVisible: (state, { payload }) => {
      return {
        ...state,
        bottomTabVisible: payload,
      };
    },
  },
});

// 1. create list action for each case
export const { setIpAddress, setBottomTabVisible } = GlobalReducer.actions;

// last export reducer
export default GlobalReducer.reducer;
