import React from "react";
import { ReactComponent as Cart } from "../Assets/Icons/cart.svg";
import { motion } from "framer-motion";

const CartIcon = ({ value = 0, className, onClick }) => {
  return (
    <li
      className={`inline-block mt-0 hover:text-gray-700 font-sans text-black align-middle overflow-visible  `}
    >
      <div onClick={onClick} role="button" className="relative flex">
        <Cart className={className} />

        {value > 0 && (
          <motion.span
            animate={{ scale: 1.1, repeatCount: "infinite" }}
            transition={{ duration: 2, repeat: Infinity, repeatDelay: 1 }}
            className="absolute -top-[5px] -right-[10px] w-4 h-4 font-mono text-xs leading-tight text-center text-white bg-red-600 rounded-full"
          >
            {value}
          </motion.span>
        )}
      </div>
    </li>
  );
};
export default CartIcon;
