import React from "react"

export default function LoadingFallback() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div
        className="spinner-border text-mainColor animate-spin inline-block w-8 h-8 border-4 rounded-full"
        role="status"
      >
        {/* <span className="visually-hidden">Loading...</span> */}
      </div>
    </div>
  )
}
