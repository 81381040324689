import React, { Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { PrivateRoute } from "../App/Hoc/RequireAuth"
import LoadingFallback from "../Components/LoadingFallback"
import PembayaranBerhasil from "../Pages/PembayaranBerhasil"
// import PilihKurirPengiriman from "../Pages/PilihKurirPengiriman"
// import ExpiredResep from "../Pages/ExpiredResep"

const Referral = React.lazy(() => import("../Pages/Referral"))

const PilihKurirPengiriman = React.lazy(() =>
  import("../Pages/PilihKurirPengiriman")
)
const ExpiredResep = React.lazy(() => import("../Pages/ExpiredResep"))
// import Akun from "../Pages/Akun"
// import AkunVoucher from "../Pages/AkunVoucher"
// import ForgotPasswordScreen from "../Pages/Auth/ForgotPassword"
// import Login from "../Pages/Auth/Login"
// import Register from "../Pages/Auth/Register"
// import ResetPasswordScreen from "../Pages/Auth/ResetPasswordScreen"
// import BannerDetail from "../Pages/BannerDetail"
// import Bantuan from "../Pages/Bantuan"
// import BatalkanTransaksi from "../Pages/BatalkanTransaksi"
// import Cart from "../Pages/Cart"
// import DaftarAlamat from "../Pages/DaftarAlamat"
// import DaftarTransaksi from "../Pages/DaftarTransaksi"
// import DaftarTransaksiDetail from "../Pages/DaftarTransaksiDetail"
// import DetailNotifikasi from "../Pages/DetailNotifikasi"
// import DetailPesanan from "../Pages/DetailPesanan"
// import DetailPoin from "../Pages/DetailPoin"
// import DetailTransaksi from "../Pages/DetailTransaksi"
// import DetailVoucher from "../Pages/DetailVoucher"
// import EditProfil from "../Pages/EditProfil"
// import ErrorPages from "../Pages/ErrorPages"
// import Home from "../Pages/Home"
// import MasukkanAlamatPengiriman from "../Pages/MasukkanAlamatPengiriman"
// import MetodePengiriman from "../Pages/MetodePengiriman"
// import Notifikasi from "../Pages/Notifikasi"
// import Order from "../Pages/Order"
// import PilihAlamat from "../Pages/PilihAlamat"
// import PilihMetodePembayaran from "../Pages/PilihMetodePembayaran"
// import PilihVoucher from "../Pages/PilihVoucher"
// import Poin from "../Pages/Poin"
// import Product from "../Pages/Product"
// import FilterProduct from "../Pages/Products/FilterProduct"
// import SearchProduct from "../Pages/Products/SearchProduct"
// import RegisterInformasiAkun from "../Pages/RegisterInformasiAkun"
// import ShowAllProduct from "../Pages/ShowAllProduct"
// import TambahAlamat from "../Pages/TambahAlamat"
// import TermsAndCondition from "../Pages/TermsAndCondition"
// import TransaksiBerhasil from "../Pages/TransaksiBerhasil"
// import UbahPassword from "../Pages/UbahPassword"
// import UploadBuktiTransfer from "../Pages/UploadBuktiTransfer"
// import Wishlist from "../Pages/Wishlist"

const Home = React.lazy(() => import("../Pages/Home"))
const Akun = React.lazy(() => import("../Pages/Akun"))
const AkunVoucher = React.lazy(() => import("../Pages/AkunVoucher"))
const ForgotPasswordScreen = React.lazy(() =>
  import("../Pages/Auth/ForgotPassword")
)
const Login = React.lazy(() => import("../Pages/Auth/Login"))
const Register = React.lazy(() => import("../Pages/Auth/Register"))
const ResetPasswordScreen = React.lazy(() =>
  import("../Pages/Auth/ResetPasswordScreen")
)
const BannerDetail = React.lazy(() => import("../Pages/BannerDetail"))
const Bantuan = React.lazy(() => import("../Pages/Bantuan"))
const BatalkanTransaksi = React.lazy(() => import("../Pages/BatalkanTransaksi"))
const Cart = React.lazy(() => import("../Pages/Cart"))
const DaftarAlamat = React.lazy(() => import("../Pages/DaftarAlamat"))
const DaftarTransaksi = React.lazy(() => import("../Pages/DaftarTransaksi"))
const DaftarTransaksiDetail = React.lazy(() =>
  import("../Pages/DaftarTransaksiDetail")
)
const DetailNotifikasi = React.lazy(() => import("../Pages/DetailNotifikasi"))
const DetailPesanan = React.lazy(() => import("../Pages/DetailPesanan"))
const DetailPoin = React.lazy(() => import("../Pages/DetailPoin"))
const DetailTransaksi = React.lazy(() => import("../Pages/DetailTransaksi"))
const DetailVoucher = React.lazy(() => import("../Pages/DetailVoucher"))
const EditProfil = React.lazy(() => import("../Pages/EditProfil"))
const ErrorPages = React.lazy(() => import("../Pages/ErrorPages"))
const MasukkanAlamatPengiriman = React.lazy(() =>
  import("../Pages/MasukkanAlamatPengiriman")
)
const MetodePengiriman = React.lazy(() => import("../Pages/MetodePengiriman"))
const Notifikasi = React.lazy(() => import("../Pages/Notifikasi"))
const Order = React.lazy(() => import("../Pages/Order"))
const PilihAlamat = React.lazy(() => import("../Pages/PilihAlamat"))
const PilihMetodePembayaran = React.lazy(() =>
  import("../Pages/PilihMetodePembayaran")
)
const PilihVoucher = React.lazy(() => import("../Pages/PilihVoucher"))
const Poin = React.lazy(() => import("../Pages/Poin"))
const Product = React.lazy(() => import("../Pages/Product"))
const FilterProduct = React.lazy(() =>
  import("../Pages/Products/FilterProduct")
)
const SearchProduct = React.lazy(() =>
  import("../Pages/Products/SearchProduct")
)
const RegisterInformasiAkun = React.lazy(() =>
  import("../Pages/RegisterInformasiAkun")
)
const ShowAllProduct = React.lazy(() => import("../Pages/ShowAllProduct"))
const TambahAlamat = React.lazy(() => import("../Pages/TambahAlamat"))
const TermsAndCondition = React.lazy(() => import("../Pages/TermsAndCondition"))
const PrivacyPolicy = React.lazy(() => import("../Pages/TermsAndCondition"))
const TransaksiBerhasil = React.lazy(() => import("../Pages/TransaksiBerhasil"))
const UbahPassword = React.lazy(() => import("../Pages/UbahPassword"))
const UploadBuktiTransfer = React.lazy(() =>
  import("../Pages/UploadBuktiTransfer")
)
const Wishlist = React.lazy(() => import("../Pages/Wishlist"))
const TentangKami = React.lazy(() => import("../Pages/TentangKami"))
const CheckoutResep = React.lazy(() => import("../Pages/CheckoutResep"))

function AppRouter() {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={
            <PrivateRoute auth={false}>
              <Login />
            </PrivateRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PrivateRoute auth={false}>
              <Register />
            </PrivateRoute>
          }
        />
        <Route
          path="/informasi-akun"
          element={
            <PrivateRoute auth={false}>
              <RegisterInformasiAkun />
            </PrivateRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <PrivateRoute auth={false}>
              <ForgotPasswordScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <PrivateRoute auth={false}>
              <ResetPasswordScreen />
            </PrivateRoute>
          }
        />
        <Route path="/product/:slug" element={<Product />} />
        <Route path="/detail-pesanan" element={<DetailPesanan />} />
        <Route path="/rincian-pesanan" element={<DetailPesanan step={3} />} />
        <Route
          path="/akun"
          element={
            <PrivateRoute>
              <Akun />
            </PrivateRoute>
          }
        />
        <Route path="/search/:keywords" element={<SearchProduct />} />
        <Route path="/search" element={<SearchProduct />} />
        <Route path="/filter" element={<FilterProduct />} />
        <Route
          path="/wishlist"
          element={
            <PrivateRoute>
              <Wishlist />
            </PrivateRoute>
          }
        />
        <Route
          path="/order"
          element={
            <Order />
            // <PrivateRoute>
            // </PrivateRoute>
          }
        />
        <Route path="/banner/:banner_id" element={<BannerDetail />} />
        <Route
          path="/alamat-pengiriman"
          element={<MasukkanAlamatPengiriman />}
        />
        <Route path="/metode-pengiriman" element={<MetodePengiriman />} />
        <Route path="/metode-pembayaran" element={<PilihMetodePembayaran />} />
        <Route path="/pilih-voucher" element={<PilihVoucher />} />
        <Route path="/detail-voucher" element={<DetailVoucher />} />

        {/* akun  */}
        <Route
          path="/akun-voucher"
          element={
            <PrivateRoute>
              <AkunVoucher />
            </PrivateRoute>
          }
        />
        <Route path="/bantuan" element={<Bantuan />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/about-us" element={<TentangKami />} />

        <Route
          path="/tambah-alamat"
          element={<TambahAlamat title={"Tambah Alamat"} />}
        />
        <Route
          path="/update-alamat"
          element={<TambahAlamat title={"Ubah Alamat"} />}
        />
        <Route path="/pilih-alamat" element={<PilihAlamat />} />
        <Route
          path="/edit-profile"
          element={
            <PrivateRoute>
              <EditProfil />
            </PrivateRoute>
          }
        />
        <Route
          path="/daftar-transaksi"
          element={
            <PrivateRoute>
              <DaftarTransaksi />
            </PrivateRoute>
          }
        />
        <Route
          path="/daftar-transaksi-detail/:transaction_id"
          element={
            <PrivateRoute>
              <DaftarTransaksiDetail />
            </PrivateRoute>
          }
        />
        <Route path="/detail-transaksi" element={<DetailTransaksi />} />
        <Route path="/transaksi-berhasil" element={<TransaksiBerhasil />} />
        <Route path="/pembayaran-berhasil" element={<PembayaranBerhasil />} />
        <Route
          path="/upload-bukti-transfer"
          element={
            <PrivateRoute>
              <UploadBuktiTransfer />
            </PrivateRoute>
          }
        />
        <Route
          path="/ubah-kata-sandi"
          element={
            <PrivateRoute>
              <UbahPassword />
            </PrivateRoute>
          }
        />
        {/* akun daftar alamat */}
        <Route
          path="/daftar-alamat"
          element={
            <PrivateRoute>
              <DaftarAlamat />
            </PrivateRoute>
          }
        />
        {/* akun daftar alamat */}
        <Route
          path="/informasi-pengiriman"
          element={<PilihKurirPengiriman />}
        />

        {/* batalkan transaksi */}
        <Route
          path="/batalkan-transaksi"
          element={
            <PrivateRoute>
              <BatalkanTransaksi />
            </PrivateRoute>
          }
        />

        {/* flimty poin  */}
        <Route
          path="/poin"
          element={
            <PrivateRoute>
              <Poin />
            </PrivateRoute>
          }
        />
        <Route
          path="/detail-poin/:voucher_id"
          element={
            <PrivateRoute>
              <DetailPoin />
            </PrivateRoute>
          }
        />

        <Route path="/referral" element={<Referral />} />

        {/* cart  */}
        <Route path="/cart" element={<Cart />} />
        <Route path="/semua-produk" element={<ShowAllProduct />} />

        {/* notifikasi */}
        <Route
          path="/notifikasi"
          element={
            <PrivateRoute>
              <Notifikasi />
            </PrivateRoute>
          }
        />
        <Route
          path="/detail-notifikasi/:notification_id"
          element={
            <PrivateRoute>
              <DetailNotifikasi />
            </PrivateRoute>
          }
        />
        <Route
          path="/transaction/:transaction_id"
          element={<CheckoutResep />}
        />
        <Route path="/transaction/expired" element={<ExpiredResep />} />

        {/* use "*"" when no path available get called */}
        <Route path="*" element={<ErrorPages />} />
      </Routes>
    </Suspense>
  )
}

export default AppRouter
