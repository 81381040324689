import { createSlice } from "@reduxjs/toolkit"
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
} from "../../utils/helper"

const initialState = {
  user: getDataFromLocalStorage("user_data"),
  status: "loading",
  message: null,
  token: getDataFromLocalStorage("auth_token"),
  keywordsSlice: "",
  formRegister: {
    name: "",
    email: "",
    telepon: "",
    brand_id: 1,
    password: "",
    method: "form",
    google_id: "form",
  },
  guestUser: getDataFromLocalStorage("guest_user"),
  guestForm: getDataFromLocalStorage("guest_form"),
  guestMessages: [],
}

// 2. create reducer
export const AuthReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setResponse: (state, { payload }) => {
      const token = `${payload?.data.token_type} ${payload?.data.access_token}`
      localStorage.setItem("auth_token", token)
      localStorage.setItem("user_data", JSON.stringify(payload?.data.user))
      return {
        ...state,
        message: payload?.message,
        user: payload?.data.user,
        token,
      }
    },
    setResponseMessage: (state, { payload }) => ({
      ...state,
      message: payload,
    }),
    setFormRegister: (state, { payload }) => ({
      ...state,
      formRegister: { ...state.formRegister, ...payload },
    }),
    setStatus: (state, { payload }) => ({
      ...state,
      status: payload,
    }),
    setToken: (state, { payload }) => {
      const token = `Bearer ${payload}`
      if (payload) {
        localStorage.setItem("auth_token", token)
      }
      return {
        ...state,
        token: payload ? token : null,
      }
    },
    setUser: (state, { payload }) => {
      localStorage.setItem("user_data", JSON.stringify(payload))
      return {
        ...state,
        user: payload,
      }
    },
    setKeywordsSlice: (state, { payload }) => ({
      ...state,
      keywordsSlice: payload,
    }),
    setGuestUser: (state, { payload }) => {
      localStorage.setItem("guest_user", JSON.stringify(payload))
      return {
        ...state,
        guestUser: payload,
      }
    },
    setGuestForm: (state, { payload }) => {
      if (payload) {
        localStorage.setItem("guest_form", JSON.stringify(payload))
      } else {
        removeDataFromLocalStorage("guest_form")
      }
      return {
        ...state,
        guestForm: payload ? { ...state.guestForm, ...payload } : null,
      }
    },

    setGuestMessages: (state, { payload }) => ({
      ...state,
      guestMessages: payload,
    }),
  },
})

// 1. create list action for each case
export const {
  setResponse,
  setResponseMessage,
  setFormRegister,
  setToken,
  setStatus,
  setUser,
  setKeywordsSlice,
  setGuestUser,
  setGuestForm,
  setGuestMessages,
} = AuthReducer.actions

// last export reducer
export default AuthReducer.reducer
