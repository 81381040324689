import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrl } from "../../utils/constant"

export const homeApi = createApi({
  reducerPath: "home_api",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Authorization", localStorage.getItem("auth_token"))
      return headers
    },
  }),
  endpoints: (builder) => ({
    tagTypes: [
      "getBanner",
      "getBannerDetail",
      "getAppInfo",
      "getPoint",
      "getNotification",
      "readNotification",
      "getPointGeneralInfo",
      "getUserProfile",
    ],
    getBanner: builder.query({
      query: () => ({ url: "/banner/all" }),
      providesTags: ["getBanner"],
    }),
    getBannerDetail: builder.query({
      query: (banner_id) => ({ url: "/banner/detail/" + banner_id }),
      providesTags: ["getBannerDetail"],
    }),
    getAppInfo: builder.query({
      query: (brand_id = 1) => ({ url: "app-info/" + brand_id }),
      providesTags: ["getAppInfo"],
    }),
    // get point user
    getPoint: builder.mutation({
      query: () => ({
        url: "/user/poin",
        method: "GET",
      }),
    }),

    // get notification
    getNotification: builder.query({
      query: () => ({
        url: "/notification/all",
      }),
      providesTags: ["getNotification"],
    }),

    // read notification
    readNotification: builder.query({
      query: (notification_id) => ({
        url: "/notification/read/" + notification_id,
      }),
      providesTags: ["readNotification"],
      invalidatesTags: ["getNotification"],
    }),

    // point general info
    getPointGeneralInfo: builder.query({
      query: () => ({
        url: "/setting-description/all",
      }),
      providesTags: ["getPointGeneralInfo"],
    }),
    getUserProfile: builder.mutation({
      query: () => ({
        url: "/user/get-profile",
        method: "GET",
      }),
      invalidatesTags: ["getNotification"],
    }),
  }),
})

export const {
  useGetBannerQuery,
  useGetBannerDetailQuery,
  useGetAppInfoQuery,
  useGetPointMutation,
  useGetNotificationQuery,
  useReadNotificationQuery,
  useGetPointGeneralInfoQuery,
  useGetUserProfileMutation,
} = homeApi
