import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const globalApi = createApi({
  reducerPath: "global_api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://www.cloudflare.com",
    prepareHeaders: (headers, { getState }) => {
      return headers
    },
  }),
  endpoints: (builder) => ({
    getIpAddress: builder.query({
      query: () => `/cdn-cgi/trace`,
    }),
  }),
})

export const { useGetIpAddressQuery } = globalApi
