import React, { useEffect } from "react"
import { ReactComponent as BackIcon } from "../Assets/Icons/backHeaderIcon.svg"
import { ReactComponent as CloseIcon } from "../Assets/Icons/close.svg"
import { ReactComponent as FlimtyHeaderLogo } from "../Assets/Logo/flimtyHeaderLogo.svg"
import { ReactComponent as SearchIcon } from "../Assets/Icons/search.svg"
import { ReactComponent as FilterHeaderIcon } from "../Assets/Icons/filterHeaderIcon.svg"
import CartIcon from "./CartIcon"
import { useDispatch, useSelector } from "react-redux"
import { setCarts } from "../App/Reducer/ProductReducer"

export default function Header({
  onBackClick,
  title,
  modal = false,
  noBotttomBorder = false,
  shadow = false,
  arrow = false,
  flimtyLogo = false,
  search = false,
  onSearch,
  searchPlaceholder,
  children,
  rightContent,
  backgroundColor = "white",
  filter = false,
  className,
}) {
  return (
    <div
      className={`
      ${className}
      ${!noBotttomBorder && `border-b-[1px] border-b-[${backgroundColor}]`} 
      ${shadow && "shadow-flimty"} 
      sticky-top 
      z-10
      bg-[${backgroundColor}]
      relative
      `}
    >
      <div
        className={`h-[4.7rem] top-0 z-50 flex items-center bg-${backgroundColor} `}
      >
        {modal && !arrow ? (
          <CloseIcon
            onClick={onBackClick}
            className={`left-4 ease-in-out duration-300 stroke-${
              backgroundColor === "white" ? "[#3D4043]" : "white"
            } hover:stroke-mainColor absolute `}
          />
        ) : (
          <BackIcon
            onClick={onBackClick}
            className={`left-4 ease-in-out duration-300  stroke-${
              backgroundColor === "white" ? "[#3D4043]" : "white"
            } hover:stroke-mainColor absolute `}
          />
        )}
        {modal && arrow && (
          <BackIcon
            onClick={onBackClick}
            className="left-4 ease-in-out duration-300 stroke-[#3D4043] hover:stroke-mainColor absolute "
          />
        )}
        <text
          className={`
          text-sm sm:text-base
          ${modal ? "pl-12 text-base font-medium" : "mx-auto"} 
          ${backgroundColor === "white" ? "text-black" : "text-white"}
          `}
        >
          {title}
        </text>
        <div className="right-4 absolute">{rightContent}</div>
        {flimtyLogo && (
          <FlimtyHeaderLogo className="w-[4rem] h-[2≈rem] sm:w-[5rem] sm:h-[3rem] absolute right-4" />
        )}
      </div>
      {search && (
        <div className="flex justify-between px-4 mb-3 bg-white">
          {/* search bar */}
          <div className="relative flex-1 text-gray-600">
            <button type="submit" className="top-3 absolute my-auto ml-4">
              <SearchIcon width={14} height={14} />
            </button>
            <input
              className="w-full pl-10 border-[1px] text-[12px] border-gray-300 bg-white h-10 px-5 rounded-[6px] focus:outline-none"
              type="text"
              name="search"
              placeholder={searchPlaceholder}
              // onChange={onSearch}
              // onClick={() => {
              //   if (!onSearch) {
              //     navigate("/search");
              //   }
              // }}
            />
          </div>

          {/* filter button  */}
          {filter && (
            <div
              className="hover:bg-white bg-mainColor border-mainColor drop-shadow-xl flex items-center w-10 h-10 ml-2 duration-300 ease-in-out delay-100 border-2 rounded-lg"
              // onClick={() => navigate("/filter")}
            >
              <div className="hover:text-mainColor mx-auto">
                <FilterHeaderIcon height={18} width={18} />
              </div>
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

export const HeaderProduct = ({
  backButton = true,
  onBackClick,
  title,
  onCartClick,
  cart = true,
}) => {
  const dispatch = useDispatch()
  const { carts } = useSelector((state) => state.product)

  useEffect(() => {
    if (cart.isSuccess || cart.isFetching) {
      dispatch(setCarts(cart?.data?.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.isSuccess, cart.isFetching])

  return (
    <div className="h-[3.5rem] sticky-top z-50 flex items-center bg-gradient-to-r from-mainColor to-white border-b-[1px] relative">
      {backButton && (
        <BackIcon
          onClick={onBackClick}
          className="left-4 stroke-white hover:stroke-secondaryColor absolute duration-300 ease-in-out"
        />
      )}
      <text className="mx-auto text-white">{title}</text>
      {cart && (
        <button onClick={onCartClick} className="right-4 absolute flex">
          <CartIcon
            className="fill-white hover:fill-mainColor mr-1 duration-300 ease-in-out"
            value={carts && carts.length}
          />
        </button>
      )}
    </div>
  )
}

export const HeaderAkun = ({
  onBackClick,
  title,
  onCartClick,
  cart = true,
}) => {
  return (
    <div className="h-[4.7rem] sticky top-0 z-50 flex items-center bg-white border-b-[1px]">
      <a className="absolute" href="/">
        <FlimtyHeaderLogo className="w-[3.5rem] sm:w-[5rem] ml-4" />
      </a>
      <text className="mx-auto">{title}</text>
    </div>
  )
}
