import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrl } from "../../utils/constant"

export const addressApi = createApi({
    reducerPath: "address_api",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            headers.set("Authorization", localStorage.getItem("auth_token"))
            return headers
        },
    }),
    tagTypes: ["address", "Kelurahan"],
    endpoints: (builder) => ({
        getProvinsi: builder.query({
            query: () => ({ url: "/address/provinsi" }),
        }),
        getKota: builder.mutation({
            query: (provinsi_id) => ({
                url: "/address/kota/" + provinsi_id,
                method: "GET",
            }),
        }),
        getKecamatan: builder.mutation({
            query: (kota_id) => ({
                url: "/address/kecamatan/" + kota_id,
                method: "GET",
            }),
        }),
        getKelurahan: builder.mutation({
            query: (kecamatan_id) => ({
                url: "/address/kelurahan/" + kecamatan_id,
                method: "GET",
            }),
        }),

        // hapus alamat mutation
        deleteAddress: builder.mutation({
            query: (body) => ({
                url: "/user/delete-address",
                method: "POST",
                body,
            }),
        }),
    }),
})

export const {
    useGetProvinsiQuery,
    useGetKotaMutation,
    useGetKecamatanMutation,
    useGetKelurahanMutation,
    useDeleteAddressMutation,
} = addressApi