import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrl } from "../../utils/constant"

export const trasactionApi = createApi({
  reducerPath: "transaction_api",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Authorization", localStorage.getItem("auth_token"))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getPaymentMethod: builder.query({
      query: () => ({ url: "/payment-method" }),
    }),
    getAddressList: builder.query({
      query: (user_id = null) => ({
        url: "/user/get-address/" + user_id,
      }),
    }),
    //save address mutation
    saveAddress: builder.mutation({
      query: ({ body, url = "/user/save-address" }) => ({
        url,
        method: "POST",
        body,
      }),
    }),

    // update address mutation
    updateAddress: builder.mutation({
      query: (body) => ({
        url: "/user/update-address",
        method: "POST",
        body,
      }),
    }),
    // transaction process
    transactionProccess: builder.mutation({
      query: (body) => ({
        url:
          body?.otp || body?.guest
            ? "/transaction/guest"
            : "/transaction/create",
        method: "POST",
        body,
      }),
    }),
    transactionGuest: builder.mutation({
      query: (body) => ({
        url: "/transaction/guest",
        method: "POST",
        body,
      }),
    }),
    // transaction detail
    transactionDetail: builder.query({
      query: (transaction_id) => ({
        url: `/transaction/detail/${transaction_id}`,
      }),
    }),
    transactionDetailPublic: builder.query({
      query: (transaction_id) => ({
        url: `/transaction/detail/public/${transaction_id}`,
      }),
    }),
    // transaction active query
    transactionActive: builder.query({
      query: () => ({
        url: "/transaction/active",
      }),
    }),
    // transaction history query
    transactionHistory: builder.query({
      query: () => ({
        url: "/transaction/history",
      }),
    }),

    // get list voucher
    getVoucher: builder.query({
      query: (token = null) => ({
        url: token ? "/voucher/all" : "/voucher/guest",
      }),
    }),

    // apply voucher
    applyVoucher: builder.mutation({
      query: (body) => ({
        url: "/transaction/apply-voucher",
        method: "POST",
        body,
      }),
    }),

    // cancel transaction
    cancelTransaction: builder.mutation({
      query: (body) => ({
        url: "/transaction/cancel",
        method: "POST",
        body,
      }),
    }),

    // delete product
    deleteProduct: builder.mutation({
      query: (body) => ({
        url: "/transaction/delete/product",
        method: "POST",
        body,
      }),
    }),

    // voucher point
    voucherPoint: builder.query({
      query: () => ({
        url: "/voucher/point",
      }),
    }),

    // point to voucher
    pointToVoucher: builder.mutation({
      query: (body) => ({
        url: "/voucher/point-to-voucher",
        method: "POST",
        body,
      }),
    }),

    // voucher point history
    voucherPointHistory: builder.query({
      query: () => ({
        url: "/voucher/point-history",
      }),
    }),

    // submit rating
    submitRating: builder.mutation({
      query: (body) => ({
        url: "/comment-rating/add",
        method: "POST",
        body,
      }),
    }),

    // track resi
    trackResi: builder.mutation({
      query: (transaction_id) => ({
        url: `/transaction/track/${transaction_id}`,
        method: "GET",
      }),
    }),
    getWarehouse: builder.mutation({
      query: () => ({
        url: `/warehouse/all`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useGetPaymentMethodQuery,
  useGetAddressListQuery,
  useSaveAddressMutation,
  useTransactionProccessMutation,
  useTransactionGuestMutation,
  useTransactionActiveQuery,
  useTransactionHistoryQuery,
  useDeleteProductMutation,
  useUpdateAddressMutation,
  useGetVoucherQuery,
  useApplyVoucherMutation,
  useCancelTransactionMutation,
  useVoucherPointQuery,
  usePointToVoucherMutation,
  useVoucherPointHistoryQuery,
  useTransactionDetailQuery,
  useTransactionDetailPublicQuery,
  useSubmitRatingMutation,
  useTrackResiMutation,
  useGetWarehouseMutation,
} = trasactionApi
