import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { invoiceUrl } from "./constant"

export function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export function seo(data = {}) {
  data.title = data.title || "Default title"
  data.metaDescription = data.metaDescription || "Default description"

  document.title = data.title
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", data.metaDescription)
}

// export fuction format number indonesia
export function formatNumber(number) {
  // change number format it's number greater than 0
  if (number > 0) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  } else {
    return 0
  }
}

export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num)
}

export const limitStringLength = (str, limit) => {
  if (str.length > limit) {
    return str.substring(0, limit) + "..."
  } else {
    return str
  }
}

// remove duplicate object in array by id
export function removeDuplicate(arr) {
  return arr.filter((item, index) => {
    return arr.indexOf(item) === index
  })
}

// push elements in object against same key javascript
export function mergeArray(array) {
  let newdata = []
  array.map((item) => {
    if (newdata[`${item.key}`] && newdata[`${item.key}`].length > 0) {
      newdata[`${item.key}`] = [...newdata[`${item.key}`], item.value]
    } else {
      newdata[`${item.key}`] = [item.value]
    }
  })
  console.log(newdata, "newdata")
  return newdata
}

// filter product by category
export function filterProductByCategory(array, category) {
  if (category === 0) {
    return array
  }
  return array.filter((item) => {
    return item?.product_categories.find((cat) => cat.id === category)
  })
}

// sum price in array of object
export function sumPrice(array) {
  let sum = 0
  // check if is array
  if (Array.isArray(array)) {
    array.map((item) => {
      sum += item.price * item.qty
    })
  }
  return sum
}

export const fileToDataUri = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })

// get invoice url
export const getInvoiceUrl = (invoice) => {
  return `${invoiceUrl}${invoice}`
}

// input whatsapp number
export const formatWhatsapp = (value, plus = true) => {
  // check value is not number
  // if (typeof value !== "number") return ""
  if (!value) return ""
  const first = value.substr(0, 2)
  const first_one_digit = value.substr(0, 1)
  const first_four_digit = value.substr(0, 4)
  const first_three_digit = value.substr(0, 3)
  const second = value.substr(2)
  const second_one_digit = value.substr(1)
  const second_four_digit = value.substr(4)
  const second_three_digit = value.substr(3)
  if (first == "08") {
    return `${plus ? "+628" : "628"}` + second
  } else if (first_three_digit == "628") {
    return `${plus ? "+628" : "628"}` + second_three_digit
  } else if (first_one_digit == "8") {
    return `${plus ? "+628" : "628"}` + second_one_digit
  } else if (first_one_digit == "9") {
    return `${plus ? "+628" : "628"}` + second_one_digit
  } else if (first_one_digit == "1") {
    return `${plus ? "+628" : "628"}` + second_one_digit
  } else if (first_one_digit == "2") {
    return `${plus ? "+628" : "628"}` + second_one_digit
  } else if (first_one_digit == "3") {
    return `${plus ? "+628" : "628"}` + second_one_digit
  } else if (first_one_digit == "4") {
    return `${plus ? "+628" : "628"}` + second_one_digit
  } else if (first_one_digit == "5") {
    return `${plus ? "+628" : "628"}` + second_one_digit
  } else if (first_one_digit == "6") {
    return `${plus ? "+628" : "628"}` + second_one_digit
  } else if (first_one_digit == "7") {
    return `${plus ? "+628" : "628"}` + second_one_digit
  } else if (first_four_digit == "+628") {
    return `${plus ? "+628" : "628"}` + second_four_digit
  }

  return value
  //   return value
  //   console.log(value)
}

// sort array of object by value asc
export const sortByValueAsc = (array, key = "price") => {
  return array.sort((a, b) => {
    return a[key] - b[key]
  })
}

// get data from localstorage
export const getDataFromLocalStorage = (key) => {
  const data = localStorage.getItem(key)
  if (data == "undefined") {
    return null
  }
  if (data == "null") {
    return null
  }

  try {
    return JSON.parse(data)
  } catch (error) {
    return data
  }
}

// set data to localstorage
export const setDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}

// remove data from localstorage
export const removeDataFromLocalStorage = (key) => {
  localStorage.removeItem(key)
}

// get shipping info
export const getShippingInfo = (shipping) => {
  if (!shipping) return null
  if (shipping.length > 0) {
    const shippingResult = shipping.find(
      (item) => item?.shipping_type_name === "JNE REG"
    )
    if (shippingResult) {
      return shippingResult
    }

    return sortByValueDesc(shipping, "shipping_price")[0]
  }
  return null
}

// sort array of object by value asc
export const sortByValueDesc = (array, key = "shipping_price") => {
  return array.sort((a, b) => {
    return b[key] - a[key]
  })
}

// unique array of object by value
export const uniqueArray = (array, key = "id") => {
  if (!array) return []
  return array?.filter((item, index) => {
    return array?.indexOf(item) === index
  })
}

// get error message
export const getErrorForm = (error, name) => {
  if (!error || !name) return null
  const msg = error[name]
  if (Array.isArray(msg) && msg.length > 0) {
    return msg[0]
  }
  return null
}

// clear cache
export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name)
    })
  })
}

// render with condition component
export const RenderIf = ({ isTrue = false, children }) =>
  isTrue ? children : null

// sum price in array of object
export function sumPriceTotal(array) {
  let sum = 0
  // check if is array
  if (Array.isArray(array)) {
    array.map((item) => {
      sum += item.product?.product_price?.final_price * item.qty
    })
  }
  return sum
}

export function weightTotal(array) {
  let sum = 0
  // check if is array
  if (Array.isArray(array)) {
    array.map((item) => {
      sum += item.product.product_weight * item.qty
    })
  }
  return sum
}

export function getWeight(weight = 0) {
  if (weight > 100) {
    return weight / 1000
  }

  return weight
}
