import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrl } from "../../utils/constant"

export const referralApi = createApi({
  reducerPath: "referral",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Authorization", localStorage.getItem("auth_token"))
      return headers
    },
  }),
  endpoints: (builder) => ({
    myCode: builder.query({
      query: () => ({ url: "/referral/mycode" }),
    }),
    referralClaim: builder.mutation({
      query: (body) => ({
        url: "referral/claim",
        method: "POST",
        body,
      }),
    }),
  }),
})

export const { useMyCodeQuery, useReferralClaimMutation } = referralApi
