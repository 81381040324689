import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  status: "completed",
  message: null,
  banners: [],
  banner: null,
  appInfo: localStorage.getItem("app_info")
    ? JSON.parse(localStorage.getItem("app_info"))
    : null,
  poin: 0,
  notifications: [],
  pointGeneralInfo: [],
}
// 2. create reducer
export const HomeReducer = createSlice({
  name: "home",
  initialState,
  reducers: {
    setBanners: (state, { payload }) => {
      return {
        ...state,
        banners: payload,
      }
    },
    setDetailBanner: (state, { payload }) => {
      return {
        ...state,
        banner: payload,
      }
    },
    setAppInfo: (state, { payload }) => {
      localStorage.setItem("app_info", JSON.stringify(payload))
      return {
        ...state,
        appInfo: payload,
      }
    },
    setPoin: (state, { payload }) => {
      return {
        ...state,
        poin: payload || 0,
      }
    },
    setNotifications: (state, { payload }) => {
      localStorage.setItem("notifications", JSON.stringify(payload))
      return {
        ...state,
        notifications: payload,
      }
    },
    setPointGeneralInfo: (state, { payload }) => {
      return {
        ...state,
        pointGeneralInfo: payload,
      }
    },
  },
})

// 1. create list action for each case
export const {
  setBanners,
  setDetailBanner,
  setAppInfo,
  setPoin,
  setNotifications,
  setPointGeneralInfo,
} = HomeReducer.actions

// last export reducer
export default HomeReducer.reducer
