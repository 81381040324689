import React, { useEffect } from "react"
import Header from "../Components/Header"
import { ReactComponent as ShopingGirl } from "../Assets/ilustrations/OnlineShopeGirl.svg"
import { Button } from "../Components/Button"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

export default function PembayaranBerhasil() {
  let navigate = useNavigate()
  const { appInfo } = useSelector((state) => state.home)
  const { transactionSuccess } = useSelector((state) => state.transaction)

  const transactionResult =
    transactionSuccess || JSON.parse(localStorage.getItem("transactionSuccess"))

  return (
    <div className="h-screen bg-white">
      <Header onBackClick={() => navigate("/")} modal noBotttomBorder />
      <div className="flex flex-col items-center justify-center">
        <ShopingGirl />
        <br />
        <text className="text-xl font-semibold">Pembayaran Berhasil!</text>
        <br />
        <text className="w-6/12 text-xs font-light text-center">
          Terimakasih sudah berbelanja di <strong>{appInfo?.brand_name}</strong>
        </text>
        <text className="w- w-9/12 text-xs font-light text-center">
          Pembayaran kamu telah kami terima, dan pesanan kamu akan segera kami
          proses.
        </text>
        <div className="bottom-20 absolute">
          <Button
            onClick={() =>
              navigate("/daftar-transaksi-detail/" + transactionResult?.id)
            }
            label="Oke, Mengerti"
            width={"w-36"}
          />
        </div>
      </div>
    </div>
  )
}
