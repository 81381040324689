import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrlUpload } from "../../utils/constant"

export const uploadApi = createApi({
  reducerPath: "upload_api",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlUpload,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Authorization", localStorage.getItem("auth_token"))
      return headers
    },
  }),
  endpoints: (builder) => ({
    uploadConfirmPayment: builder.mutation({
      query: (body) => ({
        url: "/transaction/confirm-payment",
        method: "POST",
        body,
      }),
    }),

    // update profile photo
    updateProfilePhoto: builder.mutation({
      query: (body) => ({
        url: "/update-profile-photo",
        method: "POST",
        body,
      }),
    }),
  }),
})

export const {
  useUploadConfirmPaymentMutation,
  useUpdateProfilePhotoMutation,
} = uploadApi
