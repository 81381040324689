import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  status: "new",
  message: null,
  categories: [],
  products: [],
  whislists: [],
  searchList: [],
  filters: [],
  carts: [],
  productRating: [],
}

// 2. create reducer
export const ProductReducer = createSlice({
  name: "product",
  initialState,
  reducers: {
    setCategory: (state, { payload }) => {
      return {
        ...state,
        categories: payload,
      }
    },
    setProducts: (state, { payload }) => {
      return {
        ...state,
        products: payload,
      }
    },
    setWhislists: (state, { payload }) => {
      return {
        ...state,
        whislists: payload,
      }
    },
    updateProduct: (state, { payload }) => {
      return {
        ...state,
        products: payload,
        status: "update",
      }
    },
    resetStatus: (state, { payload }) => {
      return {
        ...state,
        status: "new",
      }
    },
    setSearchList: (state, { payload }) => {
      return {
        ...state,
        searchList: payload,
      }
    },
    setFilters: (state, { payload }) => {
      return {
        ...state,
        filters: payload,
      }
    },
    setCarts: (state, { payload }) => {
      return {
        ...state,
        carts: payload,
      }
    },
    setProductRating: (state, { payload }) => {
      return {
        ...state,
        productRating: payload,
      }
    },
  },
})

// 1. create list action for each case
export const {
  setCategory,
  setProducts,
  setWhislists,
  updateProduct,
  resetStatus,
  setSearchList,
  setFilters,
  setCarts,
  setProductRating,
} = ProductReducer.actions

// last export reducer
export default ProductReducer.reducer
