import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  status: "completed",
  message: null,
  provinsi: [],
  kabupaten: [],
  kecamatan: [],
  kelurahan: [],
}

// 2. create reducer
export const AddressReducer = createSlice({
  name: "home",
  initialState,
  reducers: {
    setProvinsi: (state, { payload }) => {
      return {
        ...state,
        provinsi: payload,
      }
    },
    setKabupaten: (state, { payload }) => {
      return {
        ...state,
        kabupaten: payload,
      }
    },
    setKecamatan: (state, { payload }) => {
      return {
        ...state,
        kecamatan: payload,
      }
    },
    setKelurahan: (state, { payload }) => {
      return {
        ...state,
        kelurahan: payload,
      }
    },
  },
})

// 1. create list action for each case
export const { setProvinsi, setKabupaten, setKecamatan, setKelurahan } =
  AddressReducer.actions

// last export reducer
export default AddressReducer.reducer
