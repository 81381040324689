import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyDxl1WrLimEdDm-B_33vy4orbCf1UTGrKI",
  authDomain: "flimty-app-85948.firebaseapp.com",
  databaseURL: "https://flimty-app-85948-default-rtdb.firebaseio.com",
  projectId: "flimty-app-85948",
  storageBucket: "flimty-app-85948.appspot.com",
  messagingSenderId: "986196091171",
  appId: "1:986196091171:web:010a34f92738d2e1c2ac96",
  measurementId: "G-EE57Q8SPWL",
}
initializeApp(firebaseConfig)

export const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)
export const GetToken = (setTokenFound) => {
  getToken(messaging, {
    vapidKey:
      "BG-Ef4cqX4DaYbjrRZ_igoQX12maPDKG7ScywPI06o546GrdjW66QQTLVgTUza5CYi5t0UEp5i-IGUoTvao2Q2Q",
  })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        localStorage.setItem("device_id", currentToken)
        setTokenFound(currentToken)
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        )
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err)
      // ...
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
