import React from "react"

export const ButtonOutline = (
  {
    loading = false,
    disabled = false,
    label = "Button",
    width,
    height,
    textSize,
    color,
    onClick,
    className,
  },
  props
) => {
  if (loading) {
    return (
      <button
        className={`
      bg-grayDivider text-white w-full mx-auto delay-100 duration-300 ease-in-out hover:text-grayDivider hover:bg-white py-2 text-center rounded-lg border-grayDivider border-[1px] drop-shadow mt-2 ${className}`}
      >
        Loading...
      </button>
    )
  }
  if (disabled) {
    return (
      <button
        className={`
      bg-grayDivider text-white w-full mx-auto delay-100 duration-300 ease-in-out hover:text-grayDivider hover:bg-white py-2 text-center rounded-lg border-grayDivider border-[1px] drop-shadow mt-2 ${className}`}
      >
        {label}
      </button>
    )
  }

  return (
    <button
      className={`
    ${width ? width : "w-full"}
    ${height && height}
    ${textSize ? textSize : "text-sm py-2"}
    border
    border-mainColor hover:border-secondaryOutlineColor 
    hover:text-secondaryOutlineColor 
    bg-white 
    text-mainColor 
    mx-auto 
    delay-100 
    duration-300 
    ease-in-out 
    text-center 
    rounded-lg
    drop-shadow 
    ${className}"
    `}
      onClick={onClick}
      {...props}
    >
      {label}
    </button>
  )
}

export const Button = (
  {
    loading = false,
    disabled = false,
    label = "Button",
    width,
    height,
    textSize,
    color,
    onClick,
    className,
  },
  props
) => {
  if (loading) {
    return (
      <button
        className={`
      ${width ? width : "w-full"}
      ${height && height}
      ${textSize ? textSize : "text-sm py-2"}
      ${color ? color : "border-grayDivider hover:bg-grayDivider/80 "}
      bg-grayDivider 
      text-white 
      mx-auto 
      delay-100 
      duration-300 
      ease-in-out 
      text-center 
      rounded-lg
      drop-shadow 
      ${className}"
      `}
      >
        Loading...
      </button>
    )
  }
  if (disabled) {
    return (
      <button
        className={`
      ${width ? width : "w-full"}
      ${height && height}
      ${textSize ? textSize : "text-sm py-2"}
      ${color ? color : "border-grayDivider hover:bg-grayDivider/80 "}
      bg-grayDivider 
      text-white 
      mx-auto 
      delay-100 
      duration-300 
      ease-in-out 
      text-center 
      rounded-lg
      drop-shadow 
      ${className}"
      `}
      >
        {label}
      </button>
    )
  }

  return (
    <button
      className={`
    ${width ? width : "w-full"}
    ${height && height}
    ${textSize ? textSize : "text-sm py-2"}
    ${
      color
        ? color
        : "border-mainColor hover:bg-mainColor/80 active:bg-mainColor "
    }
    bg-mainColor 
    text-white 
    mx-auto 
    delay-100 
    duration-300 
    ease-in-out 
    text-center 
    rounded-lg
    drop-shadow 
    ${className}"
    `}
      onClick={onClick}
      {...props}
    >
      {label}
    </button>
  )
}
